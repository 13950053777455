import React from 'react'
import styled from 'styled-components';


const SelectButton = ({children, selected, onClick}) => {
    
    const SelectButtonStyles = styled.div`
        .selectbutton {
            border: 1px solid gold;
            border-radius: 5px;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-family: Montserrat;
            cursor: pointer;
            background-color: ${selected ? "gold" : ""};
            color: ${selected ? "black" : ""};
            fontWeight: ${selected ? 700 : 500};
            &:hover{
            background-color: gold;
            color: black;
            }
            width: 22%;
        }
    `
  return (
    <SelectButtonStyles >
        <span onClick={onClick} className='selectbutton'>{children}</span>
    </SelectButtonStyles>
  )
}

export default SelectButton