import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styled from 'styled-components';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {useHistory} from 'react-router-dom';
import { CryptoState } from '../CryptoContext';
import AuthModal from './Authentication/AuthModal';
import UserSidebar from './Authentication/UserSidebar';


const HeaderStyles = styled.div`
    flex:1;
    display: flex;
    position: relative;
    .typography{
        color:gold;
        cursor:pointer;
        flex:1;
        align-self: center;      
    }
    @media(max-width:600px){
        h1,h2,h3,h4,h5,h6{
            font-size: 1.125rem;
        }
    }    
`


const Header = () => {
    const history = useHistory();

    const {currency,setCurrency, user} = CryptoState();
    console.log(user);
    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

  return (
    <ThemeProvider theme={darkTheme}>
    <AppBar position="static" color='transparent'>
            <Toolbar>
            <HeaderStyles>
                <Typography 
                onClick={()=>history.push('/')} 
                className='typography'
                variant="h5"
                >Crypto Tracker
                </Typography>
                <Select value={currency} onChange={(e)=>{setCurrency(e.target.value)}} variant='outlined' style={{width:100, height:40}}>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="CAD">CAD</MenuItem>
                    <MenuItem value="PKR">PKR</MenuItem>

                </Select>
                {user?<UserSidebar/>:<AuthModal/>}
            </HeaderStyles>

            </Toolbar>
    </AppBar>
    </ThemeProvider>
  )
}

export default Header