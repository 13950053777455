import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { CryptoState } from '../../CryptoContext';
import { Avatar } from '@mui/material';
import Watchlist from '../Watchlist';
import { auth } from '../../firebase';
import { signOut } from "firebase/auth";
import styled from 'styled-components';

const ContainerStyles = styled.div`
width: 350px;
height:100%;
padding:20px;
    .sidebar-profile-container{
        font-family:monospace;
        height:100%;
        display:flex;
        flex-direction:column;
    }
    .sidebar-profile{
        flex: 0;
        display:flex;
        flex-direction:column;
        alignItems:center;
        gap:20px;
        height:92%;
        align-items: center;
    }
    .sidebar-picture{
        width:250px;
        height:250px;
        cursor:pointer;
        background-color:#EEBC1D;
        objectFit:contain;
    }
    .sidebar-watchlist{
        flex:1;
        width:100%;
        background-color:grey;
        border-radius:10px;
        padding:15px;
        padding-top:15px;
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:12px;
        overflow-y: auto;
        margin: 20px auto;
    }
`
export default function UserSidebar() {
    const [state, setState] = React.useState({
        right: false,
    });
    
    const {user, setAlert, alert} = CryptoState();
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleSubmit = ()=>{
        signOut(auth).then(() => {
        // Sign-out successful.
        setAlert({
            ...alert,
            open:true,
            message:'Successfully Signed Out',
            type:"success",
        })
        }).catch((error) => {
        // An error happened.
        setAlert({
            ...alert,
            open:true,
            message:'Failed to Sign Out',
            type:"error",
        })
        });
    }
    
    return (
        <div>
        {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
            <Avatar onClick={toggleDrawer(anchor, true)}
            style={{height:40,
            width:40,
            marginLeft:15,
            cursor:"pointer",
            backgroundColor:"#EEBC1D",}}
            src={user.photoURL}
            alt={user.displayName||user.email}
            />
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
            >
            <ContainerStyles>
            <div className='sidebar-profile-container'>
                <div className='sidebar-profile'>
                    <Avatar className='sidebar-picture' onClick={toggleDrawer(anchor, true)}
                    src={user.photoURL}
                    alt={user.displayName||user.email}/>
                    <span
                    style={{width:'100%', fontSize:25,textAlign:'center', fontWeight:700, wordWrap:'break-word',}}>
                        {user.displayName||user.email}
                    </span>
                    
                </div>
                <Watchlist className='sidebar-watchlist'/>

                <Button variant="contained" size='large' style={{marginBottom:'20px',backgroundColor: "#EEBC1D", width:'80%', alignSelf: 'center', }} onClick={handleSubmit} >Log out</Button>

            </div>
            </ContainerStyles>

            
            </Drawer>
            </React.Fragment>
        ))}
        </div>
    );
}