import React, { useState , useEffect } from 'react'
import {useParams} from "react-router-dom";
import { SingleCoin } from '../config/api';
import { CryptoState } from '../CryptoContext';
import axios from 'axios';
import CoinInfo from '../components/CoinInfo';
import { Button, LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { numberWithCommas } from '../components/Banner/Carousel';
import styled from 'styled-components';

const CoinPage = () => {
const {id} =  useParams();
const [coin, setCoin] = useState();

const {currency, symbol, user} = CryptoState();

const fetchCoin = async() =>{
  const {data} =await axios.get(SingleCoin(id));
  setCoin(data);
}

console.log(coin);

useEffect(() => {
  fetchCoin();
}, [])

const CoinPageStyles = styled.div`
  .container {
    display: flex;
    @media(max-width:900px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .sidebar {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    border-right: 2px solid grey;
      @media(max-width:900px) {
        width: 100%;
      }
  }
  .heading {
    font-weight: bold;
    margin-bottom: 20px;
    font-family: Montserrat;
  }
  .description {
    width: 100%;
    font-family: Montserrat;
    padding: 25px;
    padding-bottom: 15px;
    padding-top: 0;
    text-align: justify;
  }
  .marketData {
    align-self: start;
    padding: 25px;
    padding-top: 10px;
    width: 100%;
      @media(min-width:600px and max-width:900px) {
        display: flex;
        justify-content: space-around;
      }
      @media( max-width:767px) {
        flex-direction: column;
        align-items: "center;
      }
      @media(max-width:400px) {
      align-items: start;
      }
  }
`

if (!coin) return <LinearProgress style={{ backgroundColor: "gold" }} />;

const addToWatchList = ()=>{
  
}
return (
  <CoinPageStyles>
    <div className="container">
      <div className="sidebar">
        <img
          src={coin?.image.large}
          alt={coin?.name}
          height="200"
          style={{ marginBottom: 20 }}
        />
        <Typography variant="h3" className="heading">
          {coin?.name}
        </Typography>
        <Typography variant="subtitle1" className="description">
          {(coin?.description.en.split(". ")[0])}.
        </Typography>
        <div className="marketData">
          <span style={{ display: "flex" }}>
            <Typography variant="h5" className="heading">
              Rank:
            </Typography>
            &nbsp; &nbsp;
            <Typography
              variant="h5"
              style={{
                fontFamily: "Montserrat",
              }}
            >
              {numberWithCommas(coin?.market_cap_rank)}
            </Typography>
          </span>

          <span style={{ display: "flex" }}>
            <Typography variant="h5" className="heading">
              Current Price:
            </Typography>
            &nbsp; &nbsp;
            <Typography
              variant="h5"
              style={{
                fontFamily: "Montserrat",
              }}
            >
              {symbol}{" "}
              {numberWithCommas(
                coin?.market_data.current_price[currency.toLowerCase()]
              )}
            </Typography>
          </span>
          <span style={{ display: "flex" }}>
            <Typography variant="h5" className="heading">
              Market Cap:
            </Typography>
            &nbsp; &nbsp;
            <Typography
              variant="h5"
              style={{
                fontFamily: "Montserrat",
              }}
            >
              {symbol}{" "}
              {numberWithCommas(
                coin?.market_data.market_cap[currency.toLowerCase()]
                  .toString()
                  .slice(0, -6)
              )}
              M
            </Typography>
          </span>
          {user?<Button variant='outlined' style={{width:'100%', height:40, backgroundColor:'#EEBC1D', color:'black'}} onClick={addToWatchList}>Add to Watchlist</Button>:''}
        </div>
      </div>
      <CoinInfo coin={coin} />
    </div>
  </CoinPageStyles>
  )
}

export default CoinPage