import React from 'react';
import Styled from 'styled-components';
import { TrendingCoins } from '../../config/api';
import axios from "axios";
import { CryptoState } from '../../CryptoContext';
import { useState, useEffect } from 'react';
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from 'react-alice-carousel';
import {Link} from "react-router-dom";

const CarouselStyles = Styled.div`
    height:50%;
    display:flex;
    align-items:center;
    .carouselItem{
        display:flex;
        flex-direction:column;
        align-items:center;
        cursor:pointer;
        text-transform:uppercase;
        color:white;
    }
`
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const Carousel = () => {
    
    const [trending, setTrending] = useState([]);

    const {currency, symbol} = CryptoState();

    const fetchTrendingCoins= async ()=>{
       const { data }  = await axios.get(TrendingCoins(currency));
        setTrending(data);
    }
    console.log(trending);

    useEffect(() => {
      fetchTrendingCoins();
    }, [currency])
    
    const responsive = {
        0:{
            items:2,
        },
        512:{
            items:4,
        },
    };

    const items = trending.map((coin)=>{

        let profit = coin.price_change_percentage_24h>=0;
        return(
            <Link className='carouselItem' to={`/coins/${coin.id}`} > 
                <img
                src={coin?.image}
                alt={coin.name}
                height="80"
                style={{marginBottom:10}}
                />
                <span>
                    {coin?.symbol}
                    &nbsp;
                    <span style={{color:profit>0?'rgb(14,203,129':"red", fontWeight:500}} >
                        {profit && "+"} {coin?.price_change_percentage_24h?.toFixed(2)}%
                    </span>
                </span>
                <span style={{ fontSize: 22, fontWeight: 500 }}>
                    {symbol} {numberWithCommas(coin?.current_price.toFixed(2))}
                </span>
            </Link>
        )
    });
  return (
    <CarouselStyles>
            <AliceCarousel
                mouseTracking
                infinite
                autoPlayInterval={1000}
                animationDuration={1500}
                disableDotsControls
                responsive={responsive}
                autoPlay
                items={items}
                disableButtonsControls
                
            />
    </CarouselStyles>
  )
}

export default Carousel