import { Snackbar } from '@mui/material';
import React from 'react'
import { CryptoState } from '../CryptoContext';
import MuiAlert from '@mui/material/Alert';

const Alert = () => {
    const { alert, setAlert } =  CryptoState();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setAlert({...alert,open:false});
    };
  return (
    <Snackbar 
    open={alert.open}
    autoHideDuration={3000}
    onClose={handleClose}
    key={alert.vertical + alert.horizontal}
    anchorOrigin={{
        vertical: alert.vertical ,
        horizontal: alert.horizontal,
    }}
    >
        <MuiAlert onClose={handleClose} severity={alert.type} elevation={10} variant="filled" >
            {alert.message}
        </MuiAlert>
    </Snackbar>
    )
}

export default Alert