import React from 'react'
import { useState, useEffect } from 'react';
import { CryptoState } from '../CryptoContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { TableContainer,LinearProgress, Table, TableHead, TableRow, TableCell, TableBody, Pagination  } from '@mui/material';
import {useHistory} from 'react-router-dom';
import { numberWithCommas } from './Banner/Carousel';
import styled from "styled-components";

const CoinsTableStyles = styled.div`

.table-row{
    background-color:#16171a;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    cursor:pointer;
    &:hover{
        background-color:#131111;
    },
    font-family:Montserrat;
    th{
        gap:15px;
        padding: 16px;
        border-bottom:none;
    }
    td{
        border-bottom:none;
    }
    
}
.pagination{
    padding: 20px;
}
.pagination .MuiPaginationItem-root{
    color:gold;
}
@media(max-width:600px){
    padding: 0;
    .table-heading-text{
        font-size: 1.25rem;
    }
    .pagination{
        padding: 10px 0;
    }
    thead {
        th{
            font-size: 0.675rem;
            padding: 10px;
        }
        td{
            font-size: 0.675rem;
        }
    }
    .table-row {
        td{
            padding: 10px 0;
            font-size: 0.675rem;
        }
        th{
            padding: 10px 0;
            font-size: 0.675rem;
        }
        .table-cell-th {
            img{
                height:30px;
                align-self: center;
            }
        }
    }

`

const CoinsTable = () => {

    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const {currency, symbol, coins, loading, fetchCoins} = CryptoState();
    const history = useHistory();


    useEffect(() => {
        fetchCoins();
    }, [currency])
    
    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    const handleSearch = () =>{
        return coins.filter(
            (coin) => 
                coin.name.toLowerCase().includes(search) ||
                coin.symbol.toLowerCase().includes(search)
        );
    };
    

  return (
    <ThemeProvider theme={darkTheme}>
        <CoinsTableStyles>
            <Container style={{textAlign:'center'}}>
                <Typography className='table-heading-text' variant="h4" style={{margin:18, fontFamily:"Montserrat"}}>
                    Cryptocurrency Prices Sorted by Market Cap
                </Typography>
                <TextField label="Search For a Crypto Currency.." variant="outlined" style={{marginBottom:20, width:"100%"}} onChange={(e)=> {
                    setSearch(e.target.value.toLowerCase());
                    setPage(1);
                    
                }
                }/>
                <TableContainer>
                    {
                        loading ? (<LinearProgress style={{backgroundColor:'gold'}}/>) :(
                            <Table>
                                <TableHead style={{backgroundColor:"#EEBC1D"}}>
                                    <TableRow>
                                        {["Coin", "Price", "24h Chg", "Market Cap"].map((head)=>(
                                            <TableCell style={{color:"black", fontWeight:"700", fontFamily:"Montserrat",}} key={head}
                                            align={head==="Coin"?"":"right"}>
                                                {head}
                                            </TableCell>

                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {handleSearch().slice((page-1)*10,(page-1)*10+10).map((row)=>{
                                        const profit = row.price_change_percentage_24h >0;

                                        return(
                                            <TableRow
                                            onClick={()=>history.push(`/coins/${row.id}`)}
                                            className="table-row"
                                            key={row.name}
                                            >
                                                <TableCell 
                                                component={'th'}
                                                scope='row'
                                                className='table-cell-th'
                                                style={{display:"flex"}}
                                                >
                                                    <img
                                                    src={row?.image}
                                                    alt={row.name}
                                                    height="50"
                                                    />
                                                    <div style={{display:'flex',flexDirection:"column"}}>
                                                        <span style={{textTransform:'uppercase', fontSize:22}}>
                                                            {row.symbol}
                                                        </span>
                                                        <span style={{color:'darkgrey'}}>
                                                            {row.name}
                                                        </span>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {symbol}{" "}
                                                    {numberWithCommas(row.current_price.toFixed(2))}
                                                </TableCell>
                                                <TableCell align="right"
                                                style={{color:profit>0?"rgb(14,203,129":"red", fontWeight:500}}>
                                                    {profit && "+"}
                                                    {row.price_change_percentage_24h.toFixed(2)}%
                                                </TableCell>
                                                <TableCell align="right">
                                                    {symbol}{" "}
                                                    {numberWithCommas(row.market_cap.toString().slice(0,-6))}M
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        )
                    }
                </TableContainer>
                <Pagination  
                className='pagination'
                style={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center"
                }}
                count={(handleSearch()?.length/10).toFixed(0)}
                onChange={(_,value)=>{
                    setPage(value);
                    window.scroll(0,450);
                }}>
                </Pagination>
            </Container>
         </CoinsTableStyles>
    </ThemeProvider>
  )
}

export default CoinsTable