// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAG47a61j1-v8vLATRyWVBMfIjUbCy_IS8",
    authDomain: "crypto-tracker-14744.firebaseapp.com",
    projectId: "crypto-tracker-14744",
    storageBucket: "crypto-tracker-14744.appspot.com",
    messagingSenderId: "174711795277",
    appId: "1:174711795277:web:afd8873290f15b61e53604"
  };

  export default firebaseConfig;