import React from 'react'
import styled from 'styled-components';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Carousel from './Carousel';


const BannerStyles = styled.div`
.banner{
    background-image:url(./banner2.jpg);
}
.bannerContent{
    height:400px;
    display:flex;
    flex-direction:column;
    padding-top:25px;
    justify-content:space-around;
}
.tagline{
    height:40%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
}
@media(max-width:600px){
    .tagline{
        h2{
            font-size: 2.75rem;
        }
    }
}
`


const Banner = () => {
  return (
    <BannerStyles>
    <div className='banner'>
        <Container className='bannerContent'>
            <div className='tagline'>
                <Typography variant="h2" style={{fontWeight:"bold", marginBottom:15, fontFamily:"Montserrat"}}>
                    Crypto Tracker
                </Typography>
                <Typography variant="subtitle2" style={{color:"darkgrey", textTransform:'capitalize', fontFamily:"Montserrat"}}>
                    All information related to Crypto at your fingertips
                </Typography>
                
            </div>
            <Carousel/>
        </Container>
    </div>

    </BannerStyles>
  )
}

export default Banner