import { Box, Button, TextField } from '@mui/material';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useState } from 'react'
import { CryptoState } from '../../CryptoContext';
import { auth } from '../../firebase';


const Login = ({handleClose}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { alert, setAlert } = CryptoState();

    const handleSubmit = async () =>{
        if(!email || !password){
            setAlert(
                {...alert,
                open:"true",
                message:"Please fill all fields",
                type:"error",
            });
            return;
        }
        try {
            const result =  await signInWithEmailAndPassword(auth,email, password);

            setAlert({
                ...alert,
                open:true,
                message:`Login Succesful. Welcome Back ${result.user.email}`,
                type: "success",
            })
            handleClose();
        } catch (error) {
            setAlert({
                ...alert,
                open:true,
                message:error.message,
                type:"error",
            });
        }

    }

  return (
    <Box p={3} sx={{display:"flex", flexDirection:"column", gap:"20px",}}>
        <TextField variant='outlined' type="email" label="Enter Email" value={email} onChange={(e)=>setEmail(e.target.value)} fullWidth/>
        <TextField variant='outlined' type="password" label="Enter Password" value={password} onChange={(e)=>setPassword(e.target.value)} fullWidth/>
        <Button variant="contained" size='large' style={{backgroundColor: "#EEBC1D"}} onClick={handleSubmit} >Login</Button>

    </Box>
  )
}

export default Login