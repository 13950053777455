import {BrowserRouter, Route} from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Homepage from './Pages/Homepage';
import CoinPage from './Pages/CoinPage';
import styled from 'styled-components';
import Alert from './components/Alert';

function App() {

  const Container = styled.div`
  background-color:#14161a;
  color:white;
  min-height:100vh;
  `

    return(
      <BrowserRouter>
      <Container>
        <div >
          <Header/>
          
          <Route path='/' component={Homepage} exact/>
          <Route path='/coins/:id' component={CoinPage}/> 

        </div>
        <Alert/>
      </Container>
      </BrowserRouter>
      
     
  )
}

export default App;
