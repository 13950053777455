import React, { useEffect, useState } from 'react'
import { HistoricalChart } from '../config/api';
import { CryptoState } from '../CryptoContext';
import axios from 'axios';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line } from 'react-chartjs-2';
import { chartDays } from '../config/data';
import SelectButton from '../components/SelectButton'
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
const CoinInfoStyles = styled.div`
    {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        padding: 40px;
        @media(max-width:900px){
            width: 100%;
            margin-top: 0;
            padding: 20px;
            padding-top: 0;
        }
        
    }
`
const CoinInfo = ({coin}) => {
    const [historicalData, setHistoricalData] = useState();
    const [days, setDays] = useState(1);

    const {currency} = CryptoState();
    //const [flag,setflag] = useState(false);

console.log(coin.id);
console.log(currency);
    

    
    const fetchHistoricalData = async() =>{
        const {data} =await axios.get(HistoricalChart(coin.id,days,currency));
        setHistoricalData(data.prices);
      }
      
      
      useEffect(() => {
        fetchHistoricalData();
      }, [days])


    console.log('here' +historicalData);

    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

  return (
        <CoinInfoStyles>
                {!historicalData ?(<CircularProgress style={{color:'gold'}} size={250} thickness={1}/>):(
                   <>
                        <Line
                        data={{
                            labels: historicalData.map((coin) => {
                            let date = new Date(coin[0]);
                            let time =
                                date.getHours() > 12
                                ? `${date.getHours() - 12}:${date.getMinutes()} PM`
                                : `${date.getHours()}:${date.getMinutes()} AM`;
                            return days === 1 ? time : date.toLocaleDateString();
                            }),

                            datasets: [
                            {
                                data: historicalData.map((coin) => coin[1]),
                                label: `Price ( Past ${days} Days ) in ${currency}`,
                                borderColor: "#EEBC1D",
                            },
                            ],
                        }}
                        options={{
                            elements: {
                            point: {
                                radius: 1,
                            },
                            },
                        }}
                        />
                        <div
                        style={{
                            display:"flex",
                            marginTop:20,
                            justifyContent:"space-around",
                            width:"100%",
                        }}>
                            {chartDays.map((day)=>(
                                <SelectButton
                                key={day.value}
                                onClick={()=> setDays(day.value)}
                                selected={day.value===days}>{day.label}</SelectButton>
                            ))}
                        </div>
                    
                        </>
                )}
                 
        </CoinInfoStyles>

  )
}

export default CoinInfo