import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Login from './Login';
import Signup from './Signup';
import GoogleButton from 'react-google-button';
import styled from 'styled-components';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { CryptoState } from '../../CryptoContext';
import { auth } from '../../firebase';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  color: "white",
  borderRadius:2,
  border: '2px solid #000',
};

const StylesAuth = styled.div`
  padding:24px;
  padding-top:0;
  display:flex;
  flex-direction:column;
  text-align:center;
  gap:20px;
  font-size:20px;
`

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function AuthModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(0);
  const {alert, setAlert} = CryptoState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = ()=>{
    signInWithPopup(auth, googleProvider).then(res=>{
      setAlert({
        ...alert,
        open:true,
        message:`Login Succesful. Welcome Back ${res.user.email}`,
        type:'success',
      });
      handleClose();
    }).catch((error)=>{
      setAlert({
        ...alert,
        open:true,
        message:error.message,
        type:'error',
      });
    });
    
    return;
  }

  return (
    <div>
      <Button style={{width:85,height:40,backgroundColor:"#EEBC1D",color:"black", marginLeft:15}} onClick={handleOpen}>Login</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={ style }>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab style={{flex: "auto",}} label="Login"  {...a11yProps(0)} />
                <Tab style={{flex: "auto",}} label="Sign Up"  {...a11yProps(1)} />
              </Tabs>
      
            {value===0 && <Login handleClose={handleClose}/>}
            {value===1 && <Signup handleClose={handleClose} />}
            <StylesAuth >
              <span>OR</span>
              <GoogleButton style={{width:'100%', outline:'none'}} onClick={signInWithGoogle}/>
            </StylesAuth>
          </Box>

        </Fade>
      </Modal>
    </div>
  );
}
