import React from 'react'
import Banner from '../components/Banner/Banner'
import Styled from 'styled-components'
import CoinsTable from '../components/CoinsTable'

const Homepage = () => {
  return (

    <>
        <Banner></Banner>
        <CoinsTable></CoinsTable>
    </>
  )
}

export default Homepage